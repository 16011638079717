import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';
import Button from '@mui/material/Button';
import WordForm from './Subcomponents/WordForm';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Divider from '@mui/material/Divider';

export default class WordsEditor extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getWordsAction()
        this.getLanguagesAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getWordsAction() {
        const that = this;
        let page = 1
        if (this.state.wordsPage)
            page = this.state.wordsPage

        this.setState({wordsPage:page})
        that.props.setManagerState({loadingTrendingWords: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_WORDS + '?page=' + page + '&limit=50&accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({words: data.items, loadingTrendingWords:false, wordsPagination: data.pagination})
                }
            })
        })
    }

    getLanguagesAction() {
        const that = this;

        that.props.setManagerState({loadingTrendingWords: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALL_LANGUAGES, {},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({wordsLanguages: data.items})
                }
            })
        })
    }
    nextPage() {
        let that = this;
        this.setState({wordsPage : this.props.getManagerState().wordsPagination.current + 1}, () => {
            that.getWordsAction()
        })
    }
    previousPage()
    {
        let that = this;
        this.setState({wordsPage : this.props.getManagerState().wordsPagination.current - 1}, () => {
            that.getWordsAction()
        })
    }
    handleChangePage (e) 
    {
        this.setState({wordsPage: e.target.value})
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.rootWords}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <Paper style={classes.paperWords}>
                    {
                        this.props.getManagerState().loadingTrendingWords ?
                                <CircularProgress style={classes.cilcularProgress} text={this.props.getManagerState().language.LANG_GENERATING_FEED} />
                            :
                            <Grid container spacing={2}>
                            {
                                this.props.getManagerState().words.map((item, index)=> {
                                    return <Grid item xs={12} md={6} lg={4} key={index}>
                                                <WordForm word={item}  {...this.buildComponentProps('Appbar')} />
                                                <Divider />
                                            </Grid>
                                            
                                    })
                            }
                            </Grid>
                    }   
                        <Grid container spacing={2} style={{margin:8}}>
                            <Grid item xs={12} md={12} lg={12}>
                                {this.state.wordsPage != 1 ?
                                <IconButton color="secondary" onClick={() => {this.previousPage()}}>
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                                :
                                null
                                }
                                
                                {this.props.getManagerState().language.LANG_PAGE}: <TextField id="pagination" label={this.props.getManagerState().language.LANG_PAGE} variant="outlined" value={this.state.wordsPage} onChange={(e) => {this.handleChangePage(e)}} /> of {this.props.getManagerState().wordsPagination.last}
                                
                                <Button color="secondary" onClick={() => {this.getWordsAction()}}>{this.props.getManagerState().language.LANG_GO_TO_PAGE}</Button>
                                
                                {this.props.getManagerState().wordsPagination.current != this.props.getManagerState().wordsPagination.last ?
                                <IconButton color="secondary" onClick={() => {this.nextPage()}}>
                                    <NavigateNextIcon />
                                    </IconButton>
                                :
                                null
                                }
                            </Grid>
                        </Grid>
                    </Paper>                     
                </div>
    }
}
