import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Style from '../Styles/NewsFeedStyle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Settings from '../../../Settings/Settings';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Comments from './Comments';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Paper from '@mui/material/Paper';

export default function LinksFromPagesNewsItem (props) {
  const classes = Style();

  const [isBookmarked, setIsBookmarked] = React.useState(false)
  const [showComments, setShowComments] = React.useState(false)
  const [showFullDescription, setShowFullDescription] = React.useState(false);
  const [commentText, setCommentText] = React.useState("")
  const [comments, setComments] = React.useState([])
  const [emptyCommentsList, setEmptyCommentsList] = React.useState(false)
  const randomNumber = Math.floor((Math.random() * 100) + 1);
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods,
      toggleShowComments: () => {
        setShowComments(!showComments)
      },
      getComments: () => {
        getComments()
      }
  }


  const getComments = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_COMMENTS_1 + props.link.linksID + Settings.API_ENDPOINT_COMMENTS_2, {}, 'GET',null, (data) => {
        setComments(data.items)
        if (data.items.length === 0) setEmptyCommentsList(true)
        else  setEmptyCommentsList(false)
    })
  }

  const componentProps = {
      ...props.componentProps
  }

  const parseUrl = (url) => {
      var a = document.createElement('a');
      a.href = url;
      return a.hostname;
  }

  const postBookmarksAction = () => {
      if(componentMethods.getLocalStorage('user')) {
          componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_POST_BOOKMARKS + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash ,{bookmarksLinksLinksID:props.link.linksID },'POST',null, (data) => {
            setIsBookmarked(true)
          })
      }    
  }
  const postComment = () => {
      if(componentMethods.getLocalStorage('user')) {
          componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_COMMENTS_1 + props.link.linksID + Settings.API_ENDPOINT_COMMENTS_2 + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash ,{commentsLinksID:props.link.linksID, commentsText: commentText },'POST',null, (data) => {
            getComments()
            setCommentText("")
          })
      }    
  }
  return (
    <Card sx={{ maxWidth: 345 }} style={classes.newsFeedItem} id={randomNumber+ props.link.linksFromPagesID}>
      <CardHeader
        title={<a href={"#fromPages" + randomNumber+ props.link.linksFromPagesID} onClick={() => {window.open(props.link.linksInPagesUrl,'_blank');return false;}} style={classes.link}>{props.link.linksFromPagesTitle} <Typography variant="caption" display="block" gutterBottom>
        {componentConstants.language.LANG_CLICK_TO_READ_ARTICLE}
      </Typography></a>}
        subheader={<React.Fragment>
            <span style={classes.sublink}>{parseUrl(props.link.linksInPagesUrl)}</span>
            <a style={classes.seoLink} href={Settings.LINKS_SITE_URL + 'link/' + props.link.linksInPagesUrlHash} title={props.link.linksFromPagesTitle}>{props.link.linksFromPagesTitle}</a>
          </React.Fragment>}
      />
      {props.link.linksFromPagesDescription && props.link.linksFromPagesDescription.length ?
      <CardContent>
      <Typography variant="body2" color="text.secondary">
        {props.link.linksFromPagesDescription && props.link.linksFromPagesDescription.length > 67 && !showFullDescription ?
            <React.Fragment>
              {props.link.linksFromPagesDescription.substring(0, 66)}
              <IconButton onClick={(e) => {setShowFullDescription(!showFullDescription)}} >
                <MoreHorizIcon />
              </IconButton>
            </React.Fragment>
            :
            <React.Fragment>
              {props.link.linksFromPagesDescription && props.link.linksFromPagesDescription.length > 67 ?
                <React.Fragment>
                  <React.Fragment>
                    {props.link.linksFromPagesDescription}
                  </React.Fragment>
                    <IconButton aria-label="delete" onClick={(e) => {setShowFullDescription(!showFullDescription)}} >
                    <MoreHorizIcon />
                  </IconButton>
                </React.Fragment>
                :
                props.link.linksDescription
              }
            </React.Fragment>
          }
      </Typography>
    </CardContent>
      :
      null}
    </Card>
  );
}