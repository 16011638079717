import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Settings from '../../../Settings/Settings'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function WordForm(props) {
    const [wordsID, setWordsID] = React.useState(props.word.wordsID)
    const [wordsCorrect, setWordsCorrect] = React.useState(props.word.wordsCorrect?props.word.wordsCorrect:'')
    const [wordsWord, setWordsWord] = React.useState(props.word.wordsWord)
    const [wordsSaved, setWordsSaved] = React.useState(false)
    const [wordsLanguagesShortName, setWordsLanguagesShortName] = React.useState(props.word.wordsLanguagesShortName)
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    
    const putWords = () => {
        const that = this;
        let putObject = {wordsWord:wordsWord, wordsActive: 0, wordsLanguagesShortName: wordsLanguagesShortName}
        if (wordsCorrect)
            putObject.wordsCorrect = wordsCorrect
        else
            putObject.wordsCorrect = ''
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_WORDS + '/' +  wordsID + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash  ,putObject,'PUT',
        null, 
         () => {
            setWordsSaved(true)
            componentMethods.addError(componentConstants.language.LANG_WORDS_SUCCESSFULLY_UPDATED)
         }, 
        {}, 
        false, 
        () => {
            componentMethods.addError(componentConstants.language.LANG_WORDS_NOT_UPDATED)
        }, () => {
            componentMethods.addError(componentConstants.language.LANG_WORDS_NOT_UPDATED)

        })
    }
    const handleChange = (e) =>
    {
        setWordsCorrect(e.target.value)
    }
    const handleLanguageChange = (event) => {

        setWordsLanguagesShortName(event.target.value)
    };
  return (
    <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
            <a target="_blank" href={"/search/" + props.word.wordsWord}>{props.word.wordsWord}</a>
        </Grid>
        <Grid item xs={12} md={3} lg={4}>
            <TextField label={componentConstants.language.LANG_CORRECT_WORD} variant="outlined" color="secondary" value={wordsCorrect} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <Select
            value={wordsLanguagesShortName}
            label={componentConstants.language.LANG_SELECT_LANGUAGE}
            onChange={handleLanguageChange}
        >
            {
                componentMethods.getManagerState().wordsLanguages.map((item, index)=> {
                return <MenuItem key={index} value={item.languagesShortName}>{item.languagesName}</MenuItem>
                })
            }

        </Select>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
            <IconButton onClick={putWords} color={wordsSaved ? "primary" : "secondary"}><CheckCircleIcon /></IconButton>
        </Grid>
</Grid>
  );
}