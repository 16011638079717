import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import color from '@mui/material/colors/blue';
export default class TrendingTodayLinks extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.props.componentMethods.getTrendingLinkxFeed()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addError(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.componentMethods.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.props.componentMethods.getSubscribersFeedAction()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
        }).setComponentsConstants('LoginForm', {
            ...this.basicComponentConstants
        }).setComponentsMethods('LoginForm', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.rootNewsFeed}>                               
                    <Grid container spacing={2}>
                    {
                            <React.Fragment>
                                <Grid item xs={12} md={12} lg={12} style={classes.subscriberHeader}>
                                    <Typography variant="h6" style={{textAlign: "center", color: color[500]}}>{this.props.componentConstants.language.LANG_TRENDING_TODAY}</Typography>
                                </Grid>
                                {this.props.componentMethods.getManagerState().loadingTrendingFeed?
                                    <div style={classes.loadingOlder}>
                                    <CircularProgress style={classes.cilcularProgress} />
                                </div>
                                :
                                null
                                }
                                {this.props.componentMethods.getManagerState().trendingFeedItems.map((itemLink, indexLink) => {
                                        if (indexLink === 4)
                                        {
                                            return <Grid item xs={12} md={12} lg={12} key={indexLink}>
                                                    <NewsItem showBookmark={true} link={itemLink} key={indexLink} {...this.buildComponentProps("Appbar")} />
                                                </Grid>
                                        } else {
                                            return <Grid item xs={12} md={12} lg={12} key={indexLink}>
                                                        <NewsItem showBookmark={true} link={itemLink} key={indexLink} {...this.buildComponentProps("Appbar")} />
                                                    </Grid>
                                    }
                                    })
                                }
                            </React.Fragment>
                    }
                    </Grid>
                </div>
    }
}
