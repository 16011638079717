const settings = {
    defaultLanguage: 'en',
    API_URL: 'https://api.zootzle.com/',
    SITE_URL: "https://www.zootzle.com/",
    LINKS_SITE_URL: "https://l.zootzle.com/",
    API_ENDPOINT_REGISTER_YEAR: 'users/register/years',
    API_ENDPOINT_REGISTER:'users/register',
    API_ENDPOINT_LOGIN: 'users/login',
    API_ENDPOINT_UPDATE_PROFILE: 'account',
    API_ENDPOINT_MY_ACCOUNT: 'users/account',
    API_ENDPOINT_SUBSCRIBERS_FEED: "subscribers/feed",
    API_ENDPOINT_SUBSCRIBERS: "subscribers",
    API_ENDPOINT_SUBSCRIBERS_DAYS_WORDS: "subscribers/days/",
    API_ENDPOINT_SUBSCRIBERS_FEED_OLDER: "/older",
    API_ENDPOINT_SUBSCRIBERS_FEED_ALL: "zootzle/v2/subscriber/feed/all",
    API_ENDPOINT_SUBSCRIBERS_FEED_ALL_OLRDER: "zootzle/v2/older/subscriber/",
    API_ENDPOINT_LINK_BY_HASH: "link/",
    API_ENDPOINT_GET_LINKS: "zootzle/v2/links",
    API_ENDPOINT_GET_LINKS_V3: "zootzle/v3/links",
    API_ENDPOINT_GET_LINKS_FROM_PAGES_V3: "zootzle/v3/links/from/pages",
    API_ENDPOINT_GET_TRENDING_LINKS: "zootzle/trending/links/today",
    API_ENDPOINT_POST_BOOKMARKS: "bookmarks",
    API_ENDPOINT_TRENDING_WORDS_BY_DATE:"trending/words/date",
    API_ENDPOINT_TRENDING_BLACKLIST:"trending/blacklist",
    API_ENDPOINT_GET_LINKS_OLDER: "zootzle/v2/links/older/",
    API_ENDPOINT_SUBSCRIBERS_FEED_NEW: "zootzle/v2/subscribers/links/",
    SETTING_ACCESS_LEVEL_GOD: 0,
    SETTING_ACCESS_LEVEL_EDITOR: 98,
    API_ENDPOINT_COMMENTS_1: 'links/',
    API_ENDPOINT_COMMENTS_2: '/comments',
    API_ENDPOINT_ZOPINIONS: 'zootzle/zopinions',
    API_ENDPOINT_TRENDING_WHITELIST: 'trending/whitelist',
    API_ENDPOINT_TRENDING_WHITELISTED_TODAY: 'zootzle/trending/whitelisted/today',
    API_ENDPOINT_USER_RECOVER_PASSWORD: 'users/recover',
    API_ENDPOINT_USER_RECOVER_PASSWORD_NEW: 'users/recover/password',
    API_ENDPOINT_WORDS: 'words',
    API_ENDPOINT_WORDS_EDITED: 'words/edited',
    API_ENDPOINT_USERS: "admin/users",
    API_ENDPOINT_ADMIN_USERS_ACCESS_LEVELS: "admin/users/access/levels/",
    API_ENDPOINT_ADMIN_ACCESS_LEVELS: "admin/access/levels",
    SETTINGS_USERS_SEX_ID: {
        1: "LANG_MALE",
        2: "LANG_FEMALE",
        3: "LANG_OTHER"
    },
    API_ENDPOINT_WEBSITE_VISITORS: "website/visitors",
    API_ENDPOINT_ALL_LANGUAGES: "all/languages"
}

export default settings;