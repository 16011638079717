import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import Style from '../Styles/AppbarStyle'
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuDrawer from './MenuDrawer';
import SubscriptionsDrawer from './SubscriptionsDrawer';
import ThreePIcon from '@mui/icons-material/ThreeP';
import Fab from '@mui/material/Fab';
import CachedIcon from '@mui/icons-material/Cached';
import settings from '../../../Settings/Settings';
import TrendingDrawer from './TrendingDrawer';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '10'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    backgroundColor: "white",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
      '&:focus': {
        width: '50ch',
      },
    },
  },
}));

export default function ButtonAppBar(props) {
  const classes = Style();
  const [query, setQuery] = React.useState(props.componentConstants.urlParams && props.componentConstants.urlParams.query ? props.componentConstants.urlParams.query : "")
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const postWebsiteVisitors = () => {
    let postObject = {
        websiteVisitorsReferrer: document.referrer,
        websiteVisitorsSection: settings.API_URL,
        websiteVisitorsRequestUri: window.location.href
    }
    componentMethods.ajax(settings.API_URL + settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
  }

  const handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        if(query) {
          componentMethods.changeRoute('/search/' + encodeURI(query))
        } else {
          componentMethods.changeRoute('/')
        }
      }
  }

  const handleSearch = (event) => {
        if(query) {
          componentMethods.changeRoute('/search/' + encodeURI(query))
        } else {
          componentMethods.changeRoute('/')
        }
  }

  const handleChange = (event) => {
    setQuery(event.target.value)
  }

  const refreshPage = (event) => {
    window.location.reload();
  }
  useEffect(() => {
    if (props.postWebsiteVisitors)
      {
        postWebsiteVisitors()
      }
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
        <Fab color="secondary" onClick={refreshPage} style={classes.refreshFab} aria-label="add">
          <CachedIcon />
        </Fab>
          <MenuDrawer componentMethods={componentMethods} componentConstants={componentConstants} />
          <a href="/">
          <IconButton
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <HomeIcon />
              </IconButton>
          </a>  
          <a href="/zopinions">
          <IconButton
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <ThreePIcon />
              </IconButton>
          </a>  
          <Search>
            <SearchIconWrapper>
              <SearchIcon onClick={handleSearch} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={componentConstants.language.LANG_SEARCH_ON_ZOOTZLE}
              inputProps={{ 'aria-label': 'search' }}
              onKeyPress={handleKeyPress}
              onChange={handleChange}
              value={query}
            />
          </Search>          
        </Toolbar>
      </AppBar>
      <TrendingDrawer  componentMethods={componentMethods} componentConstants={componentConstants} />
      {componentMethods.getLocalStorage('user') ?
      <SubscriptionsDrawer componentMethods={componentMethods} componentConstants={componentConstants} />
      :
        null
      }
    </Box>
  );
}